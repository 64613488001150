<template>
    <div class="max-w py-3 bg-white border border-gray-200 rounded-lg shadow">
        <!-- Title for the Card -->
        <div class="flex items-center justify-between">
            <h2
                class="text-2xl font-semibold px-3 text-gray-600 pb-2 border-gray-300"
            >
                {{ content?.title }}
            </h2>

            <div class="flex justify-end items-center px-3">
                <button
                    v-if="eventStore.isOwner"
                    @click="toggleEditMode"
                    class="py-1.5 px-3 mb-2 text-center bg-white border rounded-md text-gray-800"
                >
                    Edit
                </button>
            </div>
        </div>
        <hr class="mb-3" />

        <div class="px-3 mb-2">
            <label class="text-gray-600">
                {{ content?.active }} :
                <span
                    v-if="promptPayData.active"
                    class="text-green-700 font-bold"
                    >เปิด</span
                >
                <span v-else class="text-red-700 font-bold">ปิด</span>
            </label>
        </div>

        <div class="px-3 mb-2">
            <label class="text-gray-600">
                {{ content?.channelLabel }} :
                <span class="text-gray-600">{{
                    promptPayData.type === "promptpay" ||
                    promptPayData?.type === undefined
                        ? content?.promptpayLabel
                        : content?.bankLabel
                }}</span>
            </label>
        </div>

        <!-- No need to display imageUrl as propmtpay has module generated -->
        <!-- QR PREVIEW -->
        <!-- <div v-if="props?.promptPayData?.imageUrl" class="p-5">
            <img :src="promptPayData.imageUrl" class="rounded-lg" />
        </div> -->

        <!-- For PromptPay Type -->
        <div
            v-if="
                promptPayData.type === 'promptpay' ||
                promptPayData?.type === undefined
            "
        >
            <div class="px-3 mb-2">
                <p class="text-gray-600">
                    {{ content?.fullName }} : {{ promptPayData.name || "-" }}
                </p>
            </div>
            <div class="px-3 mb-2">
                <p class="text-gray-600">
                    {{ content?.promptpayAccount }} :
                    {{ promptPayData.account || "-" }}
                </p>
            </div>
            <PromptpayQrcode
                class="mt-3"
                v-if="promptPayData.account"
                :promptpayId="promptPayData.account"
                :fullName="promptPayData.name"
            />
        </div>

        <!-- For Bank Type -->
        <div v-if="promptPayData.type === 'bank'">
            <div class="px-3 mb-2">
                <p class="text-gray-600">
                    {{ content?.bankLabel }} :
                    {{ promptPayData?.bank || "-" }}
                </p>
            </div>
            <div class="px-3 mb-2">
                <p class="text-gray-600">
                    {{ content?.accountName }} :
                    {{ promptPayData.name || "-" }}
                </p>
            </div>
            <div v-if="props?.promptPayData?.imageUrl" class="p-5">
                <img
                    :src="promptPayData.imageUrl"
                    class="rounded-lg mx-auto"
                    width="318"
                    height="auto"
                    alt="imageUrl"
                />
            </div>
        </div>

        <Teleport v-if="isShowModal" to="body">
            <UtilsModalDefault :isShow="isShowModal" @closed="closeModal">
                <template v-slot:body>
                    <div class="bg-red-500 px-5 py-3 rounded-xl shadow-md">
                        <div>
                            <p class="text-xl">{{content?.error?.title}}</p>
                            <p class="mt-3">{{content?.error?.description}}</p>
                            <p class="mt-1 text-yellow-200">({{content?.error?.remark}})</p>
                        </div>

                        <div class="mt-4 flex justify-end">
                            <button
                                @click="closeModal"
                                type="button"
                                class="py-2 px-6 font-medium mx-auto focus:outline-none border items-center bg-white text-red-500 rounded-lg focus:z-10"
                                >
                                <i class="fa-solid fa-xmark mx-1 text-lg"></i>
                                Close
                            </button>
                        </div>
                    </div>
                </template>
            </UtilsModalDefault>
            <!-- <V2PromptpayPinModal
                :content="content?.confirm"
                :isShow="isShowModal"
                :pin="pin"
                @update="emit('onEdit', !props.isEdit)"
                @closed="closeModal"
                @resend="sendPin"

            /> -->
        </Teleport>
    </div>
</template>

<script setup>
import { makeid } from '~/utils/makeid'
import dayjs from 'dayjs'
import _ from 'lodash'

const authStore = useAuthStore();
const eventStore = useEventStore();
const userStore = useUserStore();
const notificationStore = useNotificationStore();

const props = defineProps({
    content: { type: Object, default: {} },
    isEdit: { type: Boolean, required: true },
    promptPayData: { type: Object },
    eventPackage: { type: String, required: true },
});

const emit = defineEmits(["onEdit"]);

const isShowModal = ref(false)
const pin = ref('')

const toggleEditMode = async() => {
    if(!eventStore.isRealOwner) {
        isShowModal.value = true;
        // await initSendPin()
        return 
    }

    emit("onEdit", !props.isEdit);
};

const closeModal = () => {
    isShowModal.value = false
    pin.value = ''
}


const initSendPin = async () => {
    const expireDate = eventStore?.event?.promptpayVerify?.expire
    const isExpired = checkExpired(expireDate)

    // รหัสยังไม่หมดอายุ ใช้รหัสเดิมได้อยู่
    if(!isExpired) {
        pin.value = eventStore?.event?.promptpayVerify?.pin
        console.log(pin.value)
        return
    }

    await sendPin()
}


const sendPin = async () => {
    pin.value = makeid(4, '1234567890')
    console.log(pin.value)

    const ownerData = await getOwnerData()

    if(!ownerData?.phoneNumber) {
        alert('Something went wrong, Can not found phone number, please contact support')
        return
    }

    const verifyPayload = genVerifyPayload(ownerData?.phoneNumber)


    await Promise.all([
        eventStore.updatePromptpayPinVerify(verifyPayload),
        notificationStore.sendToSms({
            createdAt: new Date().toISOString(),
            from: "photowishApp",
            message: `[ข้อความอัตโนมัติ Event ID : ${eventStore?.event?.eventId}] %0AOTP: ${pin.value} รหัสยืนยันสำหรับเปลี่ยนแปลงข้อมูลพร้อมเพย์หรือบัญชีธนาคารในระบบ Photowish และรหัสจะหมดอายุภายใน 5 นาที`,
            to: [ownerData?.phoneNumber]
        })
    ])
    console.log('send pin & save to firestore success')
}



// ------- utils --------
const checkExpired = (expireDate) => {
    if(!expireDate) {
        return true
    }

    const currentDate = new Date();
    const expirationDate = new Date(expireDate);

    // Check if the current date is past the expiration date
    if (currentDate > expirationDate) {
        console.log('The date has expired.');
        return true
    } else {
        console.log('The date is still valid.');
        return false
    }
}

const getOwnerData = async () => {
    const user = eventStore?.event?.user
    const userIdOwner = Object.keys(user).find(key => user[key] === true);

    if(!userIdOwner) {
        alert('Something went wrong, please try again')
        return
    }
    const ownerData = await userStore.getUserById(userIdOwner)
    return ownerData
}

const  genVerifyPayload = (phoneNumber) => {
    let currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + 5);

    const data = {
        promptpayVerify: {
            to: phoneNumber,
            pin: pin.value,
            createAt: new Date().toISOString(),
            expire: currentDate.toISOString()
        }
    }

    return data
}
</script>
