<template>
  <!-- EDIT MODE -->
  <event-promptpay-edit
    v-if="isEdit"
    :content="content"
    :isEdit="isEdit"
    :promptPayData="PromptPayData"
    :callback="onSave"
    @onEdit="handleChangeEditMode"
  />

  <!-- SHOW MODE -->
  <event-promptpay-show
    v-else
    :content="content"
    @onEdit="handleChangeEditMode"
    :isEdit="isEdit"
    :eventPackage="eventPackage"
    :promptPayData="PromptPayData"
  />
  
  <!-- <AppBlockScreen
    :isShow="eventPackage == 'free' ? true : false"
    @submit="savePromptpayInfo"
    class="mx-2 mb-3 rounded-lg shadow"
  >
    <template #description>
      <p class="mt-3">ปรับแต่งข้อมูล</p>
      <p class="">
        สำหรับแพคเกจ
        <span class="underline" @click="$router.push('/package/checkout')"
          >Pro</span
        >
        เท่านั้น
      </p>
    </template>
  </AppBlockScreen> -->
</template>

<script setup>
import { useEventStore } from "@/stores/event";
const useEvent = useEventStore();
const props = defineProps(["content", "PromptPayData", "savePromptpayInfo"]);
const eventPackage = computed(() => useEvent.event && useEvent.event.package);
const isEdit = ref(false);

const handleChangeEditMode = (_isEdit) => {
  isEdit.value = _isEdit;
};

const onSave = () => {
  isEdit.value = false;
  props.savePromptpayInfo();
};
</script>
