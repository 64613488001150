<template>
    <div class="max-w py-3 bg-white border border-gray-200 rounded-lg shadow">
        <!-- Title for the Card -->
        <div class="flex items-center justify-between">
            <h2
                class="text-2xl font-semibold px-3 text-gray-600 pb-2 border-gray-300"
            >
                {{ content?.title }}
            </h2>
        </div>
        <hr class="mb-3" />

        <!-- Enable Feature -->
        <div class="py-4 px-3 inline-flex items-center">
            <label class="text-gray-600">{{ content?.active }}</label>
            <label
                class="relative inline-flex items-center cursor-pointer ml-2"
            >
                <input
                    type="checkbox"
                    v-model="promptPayDataRef.active"
                    class="sr-only peer"
                />
                <div
                    class="mr-3 w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
                ></div>
            </label>
        </div>

        <div v-if="promptPayDataRef.active" class="px-3">
            <!-- Form Selection -->
            <div class="py-4 space-x-2">
                <label class="block text-gray-600"
                    >{{ content.channelLabel }}
                </label>
                <div class="space-y-2">
                    <div class="flex items-center">
                        <input
                            type="radio"
                            id="promptpay"
                            value="promptpay"
                            v-model="promptPayDataRef.type"
                            class="mr-2"
                        />
                        <label for="promptpay" class="text-gray-600">{{
                            content.promptpayTab
                        }}</label>
                    </div>
                    <div class="flex items-center">
                        <input
                            type="radio"
                            id="bank"
                            value="bank"
                            v-model="promptPayDataRef.type"
                            class="mr-2"
                        />
                        <label for="bank" class="text-gray-600">{{
                            content.bankAccountTab
                        }}</label>
                    </div>
                </div>
            </div>

            <!-- Propmt Pay Form -->
            <div v-if="promptPayDataRef.type === 'promptpay'">
                <!-- PromptPay Form -->
                <div class="mb-2 mt-2">
                    <label class="text-gray-600">{{ content?.fullNameEdit }}</label>
                    <input
                        class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-blue-500 hover:shadow disabled:bg-gray-200"
                        type="text"
                        v-model="promptPayDataRef.name"
                    />
                </div>

                <div class="mb-2">
                    <label class="text-gray-600 mt-2">{{
                        content?.promptpayAccountEdit
                    }}</label>
                    <input
                        class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-blue-500 hover:shadow disabled:bg-gray-200"
                        type="text"
                        v-model="promptPayDataRef.account"
                    />
                </div>

                <!-- SHOW QR PREVIEW -->
                <div v-show="promptPayDataRef.account">
                    <PromptpayQrcode
                        class="mt-3"
                        :promptpayId="promptPayDataRef.account"
                        :fullName="promptPayDataRef.name"
                    />
                </div>
            </div>

            <!-- Bank Account Form -->
            <div v-if="promptPayDataRef.type === 'bank'">
                <div class="mb-2">
                    <label class="text-gray-600">{{ content.selectBank }}</label>
                    <select
                        v-model="selectedBank"
                        class="w-full py-3 border  rounded-lg px-3 focus:border-blue-500 hover:shadow placeholder:text-gray-400"
                        :class="invalidInput.bank ? 'border-red-500' : 'border-slate-200'"
                        @change="onChangeInput('bank')"
                    >
                        <option disabled>
                            {{ content.selectBankPlaceholder }}
                        </option>
                        <option
                            v-for="bank in banks"
                            :key="bank.code"
                            :value="bank"
                        >
                            {{ bank.name }}
                        </option>
                    </select>
                    <p class="text-red-500" v-if="invalidInput.bank">*กรุณากรอกข้อมูล*</p>
                </div>

                <div class="mb-2">
                    <label class="text-gray-600">{{ content.accountName }}</label>
                    <input
                        class="w-full py-3 border rounded-lg px-3 focus:outline-none focus:border-blue-500 hover:shadow"
                        type="text"
                        v-model="promptPayDataRef.name"
                        :class="invalidInput.name ? 'border-red-500' : 'border-slate-200'"
                        @input="onChangeInput('name')"
                    />
                    <p class="text-red-500" v-if="invalidInput.name">*กรุณากรอกข้อมูล*</p>
                </div>

                <div class="mb-2">
                    <label class="text-gray-600">{{
                        content.accountNoLabel
                    }}</label>
                    <input
                        class="w-full py-3 border rounded-lg px-3 focus:outline-none focus:border-blue-500 hover:shadow"
                        type="text"
                        v-model="promptPayDataRef.account"
                        :class="invalidInput.account ? 'border-red-500' : 'border-slate-200'"
                        @input="onChangeInput('account')"
                    />
                    <p class="text-red-500" v-if="invalidInput.account">*กรุณากรอกข้อมูล*</p>
                </div>

                <div class="my-5">
                    <div>
                        <p class="text-gray-600 ">{{ content?.uploadQrCode }}</p>
                        <p class="text-red-500 mb-3 text-sm">{{ content?.remarkUploadQrCode }}</p>
                        
                        <div class="flex justify-end mb-5">
                            <button 
                                @click="openExampleModal" 
                                class="h-8 px-4 text-sm text-white bg-indigo-700 rounded-lg"
                            >{{content?.example}}</button>
                        </div>
                    </div>
                    
                    <!-- Show Image Preview with Edit Icon if QR Code URL Exists -->
                    <div
                        v-if="props?.promptPayData?.imageUrl"
                        class="relative mb-2 w-[318px] mx-auto"
                    >
                        <img
                            :src="props?.promptPayData?.imageUrl"
                            class="rounded-lg"
                        />
                        <button
                            @click="clearBankQrcode"
                            class="absolute top-0 right-0 mt-2 mr-2 p-2 bg-white border border-gray-300 rounded-md hover:bg-gray-100"
                        >
                            <i class="fas fa-pen text-gray-600"></i> เปลี่ยนภาพ
                        </button>
                    </div>

                    <!-- File Upload if no QR Code URL -->
                    <input
                        v-if="!props?.promptPayData?.imageUrl"
                        type="file"
                        @change="handleFileUpload"
                        class="w-full py-3 border  rounded-lg px-3 focus:outline-none hover:shadow"
                        :class="invalidInput.imageUrl ? 'border-red-500' : 'border-slate-200'"
                    />
                    <p class="text-red-500" v-if="invalidInput.imageUrl">*กรุณากรอกข้อมูล*</p>
                </div>
            </div>
        </div>

        <!-- Save & Cancel -->
        <div class="flex justify-end mt-1 px-3">
            <button
                class="py-1.5 px-3 m-1 text-center bg-blue-700 border rounded-md text-white hover:bg-violet-500 hover:text-gray-100"
                @click="onSave"
            >
                Save
            </button>
            <button
                class="py-1.5 px-3 m-1 text-center bg-red-700 border rounded-md text-white hover:bg-violet-500 hover:text-gray-100"
                @click="toggleEditMode"
            >
                Cancel
            </button>
        </div>

        <Teleport v-if="isShowModalExample" to="body">
            <PreviewExampleModal
                :isShow="isShowModalExample"
                @closed="closeModal"
            />
        </Teleport>
    </div>
</template>

<script setup>
import { ref } from "vue";
import useStorage from "../../../services/storage/storage";
import PreviewExampleModal from '../../v2/promptpay/PreviewExampleModal.vue'

const { upload } = useStorage();

const props = defineProps({
    content: { type: Object, default: {} },
    isEdit: { type: Boolean, required: true },
    callback: { type: Function, required: true },
    promptPayData: { type: Object, required: true },
});

const promptPayDataRef = ref({
    ...props.promptPayData,
    type: props.promptPayData?.type || "promptpay",
});
const tempFile = ref(null);
const selectedBank = ref("");
const isShowModalExample = ref(false)

onMounted(() => {
    selectedBank.value = {
        code: promptPayDataRef?.value?.code || "",
        name: promptPayDataRef?.value?.bank || ""
    }
})

const banks = ref([
    { name: "ธนาคารกสิกรไทย (KBANK)", code: "KBANK" },
    { name: "ธนาคารกรุงเทพ (BBL)", code: "BBL" },
    { name: "ธนาคารกรุงศรีอยุธยา (BAY)", code: "BAY" },
    { name: "ธนาคารกรุงไทย (KTB)", code: "KTB" },
    { name: "ธนาคารทหารไทยธนชาต (TMB)", code: "TMB" },
    { name: "ธนาคารออมสิน (GSB)", code: "GSB" },
    { name: "ธนาคารไทยพาณิชย์ (SCB)", code: "SCB" },
    { name: "ธนาคารอาคารสงเคราะห์ (GHBANK)", code: "GHBANK" },
    { name: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร (BAAC)", code: "BAAC" },
    { name: "ธนาคารอิสลามแห่งประเทศไทย (IBANK)", code: "IBANK" },
    { name: "ธนาคารทิสโก้ (TISCO)", code: "TISCO" },
    { name: "ธนาคารซีไอเอ็มบีไทย (CIMBT)", code: "CIMBT" },
    { name: "ธนาคารยูโอบี (UOB)", code: "UOB" },
    { name: "ธนาคารแลนด์ แอนด์ เฮ้าส์ (LH)", code: "LH" }
]);

watch(selectedBank, (newBank) => {
    if (newBank) {
        promptPayDataRef.value.bank = newBank?.name || "";
        promptPayDataRef.value.code = newBank?.code || "";
    }
});

const emit = defineEmits(["onEdit"]);

const toggleEditMode = () => {
    emit("onEdit", !props.isEdit);
};

const invalidInput = reactive({
    bank: false,
    name: false,
    account: false,
    imageUrl: false
}) 

const isInvalidForm = computed(() => {
    let counter = 0

    if(promptPayDataRef.value?.type !== 'bank') {
        return false
    }

    if(!selectedBank.value?.name || !selectedBank.value?.code) {
        invalidInput.bank = true
        counter++
    }

    if(!promptPayDataRef.value?.name) {
        invalidInput.name = true
        counter++
    }

    if(!promptPayDataRef.value?.account) {
        invalidInput.account = true
        counter++
    }

    if(!props?.promptPayData?.imageUrl && !tempFile.value) {
        invalidInput.imageUrl = true
        counter++
    }

    return counter > 0
})

const onChangeInput = (input) => {
    invalidInput[input] = false
}

const onSave = async () => {
    if(isInvalidForm.value) {
        return
    }

    const payload = promptPayDataRef.value;

    if (tempFile.value) {
        const { downloadURL } = await upload(
            `/bankQrcodes/${tempFile.value.name}`,
            tempFile.value,
            {},
        );
        payload["imageUrl"] = downloadURL;
    }

    Object.assign(props.promptPayData, payload);

    toggleEditMode();
    props.callback();
    promptPayDataRef.value = {};
    tempFile.value = null;
};

const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
        invalidInput.imageUrl = false
        tempFile.value = file;
    }
};

const clearBankQrcode = () => {
    props.promptPayData.imageUrl = null;
};

const openExampleModal = () => {
    isShowModalExample.value = true
}
const closeModal = () => {
    isShowModalExample.value = false
}
</script>
